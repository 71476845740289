<template>
  <div :class="colSize" class="row q-mt-md">
    <div class="col-12 q-px-sm text-weight-bold">Geboortedatum</div>
    <div class="col-6 q-px-sm q-mt-none">
      <q-input
        :readonly="readonly || prospectInformationBlock"
        hide-bottom-space
        dense
        filled
        placeholder="DD-MM-YYYY"
        hint="Dag-Maand-Jaar"
        :modelValue="birth_date"
        @change="birth_date = $event"
        mask="##-##-####"
        :rules="birthdateDatePickerRule"
        class="q-mb-none q-pb-none"
      >
        <q-tooltip class="bg-primary text-body2" :offset="[50, -95]">
          Dit wordt gebruikt om vast te stellen dat de prospect meerderjarig is.
        </q-tooltip>
        <template v-slot:append>
          <q-icon
            name="event"
            class="cursor-pointer"
            v-show="!readonly && !prospectInformationBlock"
          >
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-date
                :key="birth_date"
                v-model="birth_date"
                mask="DD-MM-YYYY"
                :options="dateOptionsFn"
                :default-year-month="defaultYearMonth"
                :navigation-min-year-month="navigationMinYearMonth"
                :navigation-max-year-month="navigationMaxYearMonth"
                :readonly="readonly || prospectInformationBlock"
                no-unset
              >
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useQuasar, date } from "quasar";
import { useStore } from "vuex";
import { validate } from "../../plugins/validation.js";

export default {
  setup() {
    const $q = useQuasar();
    const $store = useStore();

    const currentYear = date.formatDate(Date.now(), "YYYY");
    const navigationMinYearMonth = ref(currentYear - 110 + "/12");
    const navigationMaxYearMonth = ref(date.formatDate(Date.now(), "YYYY/MM"));
    const formRef = ref(null);
    const defaultYearMonth = ref(currentYear - 18 + "/12");

    const loading = computed({
      get() {
        return $store.getters.loading ?? false;
      },
      set(newVal) {
        $store.dispatch("setLoading", {
          key: newVal[0],
          value: newVal[1],
        });
      },
    });

    const dateOptionsFn = (dateString) => {
      const baseYear = date.formatDate(Date.now(), "YYYY") - 18;
      const year = date.formatDate(dateString, "YYYY");

      if (year <= baseYear - 110) {
        return false;
      }

      return true;
    };

    const birth_date = computed({
      get() {
        return $store.getters.Gebdat ?? "";
      },
      set(datePickerVal) {
        $store.dispatch("setProjectSpecificValue", {
          key: "Gebdat",
          value: datePickerVal,
        });

        const dateObj = getDateColums(datePickerVal);

        $store.dispatch("updateRecord", {
          key: "GeboorteDag",
          value: dateObj.day,
        });

        $store.dispatch("updateRecord", {
          key: "GeboorteMaand",
          value: dateObj.month,
        });

        $store.dispatch("updateRecord", {
          key: "GeboorteJaar",
          value: dateObj.year,
        });
      },
    });
    const buildGebdat = (year, month, day) => {
      return (
        year +
        "-" +
        (month >= 10 ? month : "0" + month) +
        "-" +
        (day >= 10 ? day : "0" + day)
      );
    };

    const readonly = computed(
      () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
    );

    const prospectInformationBlock = computed(() =>
      $store.getters.view == "retention" ? true : false
    );

    const birthdateDatePickerRule = [
      (val) => (val !== null && val !== "") || "Vul een waarde in",
      (val) => {
        return validateDatePicker(val);
      },
    ];
    const validateGebdat = (year, month, day) => {
      if (year == "" || month == "" || day == "") {
        return "Vul een volledige datum in";
      }
      let birth_date = buildGebdat(year, month, day);
      let newDate = date.extractDate(birth_date, "YYYY-MM-DD");
      let toDay = date.extractDate(
        date.formatDate(new Date(), "YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      let age = toDay.getFullYear() - newDate.getFullYear();
      let m = toDay.getMonth() - newDate.getMonth();
      if (m < 0 || (m === 0 && toDay.getDate() < newDate.getDate())) {
        age--;
      }
      if (!date.isValid(newDate)) {
        return "Vul een valide datum in";
      }
      if (birth_date != date.formatDate(newDate, "YYYY-MM-DD")) {
        return "Vul een correcte datum in";
      }
      if (age < 18) {
        return "Deze persoon is jonger dan 18 jaar";
      }
      if (age >= 110) {
        return "Deze persoon is 110 jaar of ouder";
      }
      return true;
    };

    const validateDatePicker = (dateString) => {
      const dateColums = getDateColums(dateString);

      return validateGebdat(dateColums.year, dateColums.month, dateColums.day);
    };

    const getDateColums = (dateString) => {
      const tmp = dateString.split("-");
      const newDate = new Date(tmp[2], tmp[1] - 1, tmp[0]);

      const year = date.formatDate(newDate, "YYYY");
      const month = date.formatDate(newDate, "M");
      const day = date.formatDate(newDate, "D");

      return { year, month, day };
    };

    return {
      colSize: computed(() => {
        return $q.screen.lt.md ? "col-12" : "col-6";
      }),
      formRef,
      readonly,
      dateOptionsFn,
      navigationMinYearMonth,
      navigationMaxYearMonth,
      birthdateDatePickerRule,
      birth_date,
      loading,
      defaultYearMonth,
      prospectInformationBlock,
    };
  },
  methods: {
    validate,
  },
};
</script>
